import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import COLORS from "../constants/colors"
import SubPageHeroBanner from "../components/SubPageHeroBanner"
import TextArea from "../components/TextArea"
import { Grid, makeStyles } from "@material-ui/core"
import BackgroundImage from "gatsby-background-image"
import FONTS from "../constants/fonts"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  contentContainer: {
    backgroundColor: COLORS.WHITE,
  },
  bigImageWrapper: {
    padding: "0px 40px 0px 40px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  halfImageWrapperLeft: {
    width: "auto",
    paddingLeft: "40px",
    paddingRight: "20px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  halfImageWrapperRight: {
    width: "auto",
    color: "white",
    paddingLeft: "20px",
    paddingRight: "40px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  halfImage: {
    height: "80vh",
    objectFit: "cover",
  },
  bgImage: {
    height: "60vh",
    backgroundSize: "cover",
  },
  imageTitle: {
    fontSize: 36,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    marginBottom: 32,
    whiteSpace: "none",
    [theme.breakpoints.down("md")]: {
      fontSize: FONTS.SIZE.XM,
    },
  },
  imageText: {
    lineHeight: 1.5,
    fontSize: 18,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    [theme.breakpoints.down("md")]: {
      fontSize: FONTS.SIZE.XM,
      fontWeight: FONTS.WEIGHT.REGULAR
    },
  },
  textContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
  },
  doubleImageSection: {
    paddingBottom: "64px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  graySection: {
    backgroundColor: COLORS.LIGHT_GREY,
    paddingTop: 64,
    paddingBottom: 64,
  },
}))

const Solutions = ({ data }) => {
  const classes = useStyles()
  const orderedData =
    data.allContentfulGreenMetalsOrderedList.edges[0].node.items
  const textSection1 = orderedData.filter(
    data => data.identifier === "Solutions Page Text Section 1"
  )[0]
  const imageSection1 = orderedData.filter(
    data => data.identifier === "Solutions Page Image Section 1"
  )[0]
  const imageSection2 = orderedData.filter(
    data => data.identifier === "Solutions Page Image Section 2"
  )[0]
  const imageTextSection = orderedData.filter(
    data => data.identifier === "Solution Page Image Text Section 1"
  )[0]
  const textSection2 = orderedData.filter(
    data => data.identifier === "Solutions Page Text Section 2"
  )[0]
  return (
    <Layout>
      <SubPageHeroBanner
        textPosition="Left"
        image={data.solutions.childImageSharp.fluid}
        title="Solutions"
      />
      <div className={classes.root}>
        <Grid container className={classes.contentContainer}>
          <TextArea
            textLeft={textSection1.title}
            textRight={textSection1.description.description}
          />
          <div className={classes.graySection}>
            <div
              className={`${classes.bigImageWrapper} ${classes.doubleImageSection}`}
            >
              <BackgroundImage
                Tag="section"
                className={classes.bgImage}
                fluid={imageSection1.images[0].fluid}
              />
            </div>
            <Grid container>
              <Grid item xs={12} md={5}>
                <div className={classes.halfImageWrapperLeft}>
                  <BackgroundImage
                    Tag="section"
                    className={classes.bgImage}
                    fluid={imageTextSection.image.fluid}
                  />
                </div>
              </Grid>
              <Grid className={classes.textContainer} item xs={12} md={5}>
                <div>
                  <h4 className={classes.imageTitle}>
                    {" "}
                    {imageTextSection.title}
                  </h4>
                  <p className={classes.imageText}>
                    {" "}
                    {imageTextSection.description.description}{" "}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
          <TextArea
            textLeft={textSection2.title}
            textRight={textSection2.description.description}
          />
          <Grid className={classes.doubleImageSection} item xs={12} md={6}>
            <div className={classes.halfImageWrapperLeft}>
              <BackgroundImage
                Tag="section"
                className={classes.bgImage}
                fluid={imageSection2.images[0].fluid}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.halfImageWrapperRight}>
              <BackgroundImage
                Tag="section"
                className={classes.bgImage}
                fluid={imageSection2.images[1].fluid}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default Solutions

export const query = graphql`
  query SolutionPageQuery {
    solutions: file(relativePath: { eq: "sub_hero_solutions.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulGreenMetalsOrderedList(
      filter: { listId: { eq: "solutionPageContent" } }
    ) {
      edges {
        node {
          items {
            ... on ContentfulGreenMetalsTextSection {
              identifier
              title
              description {
                description
              }
            }
            ... on ContentfulGreenMetalsImageSection {
              identifier
              images {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
            ... on ContentfulGreenMetalsImageTextSection {
              identifier
              title
              description {
                description
              }
              image {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
